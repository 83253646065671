import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, ColumnLayout, FormField, Modal, RadioGroup, Select, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { ACTIONS } from 'src/services/period-charges/PeriodChargesReducer';
import StringUtils from 'src/utils/stringUtils';
import { GlobalAppContext } from '../App';

import { PeriodChargesContext } from './PeriodChargesView';

export default function FileUploadModal(
    props: {
        visible: boolean, 
        onCancel: () => any, 
        onSubmit: () => any
    }) {

    const { visible, onCancel, onSubmit } = props;

    const { userProfile } = React.useContext(GlobalAppContext);
    const { state, dispatch, services } = useContext(PeriodChargesContext);
    const { s3KeyForUpload } = state;
    
    const hiddenFileInput = useRef(null);
    const [uploadFile, setUploadFile] = useState();
    const [fileInfo, setFileInfo] = useState(null as unknown as any);
    const [uploadFileValid, setUploadFileValid] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState('');
    

    const [uploadResult, uploading, uploadError] = services.periodChargesService.uploadFile(s3KeyForUpload, uploadFile as unknown as File);
    
    useEffect(() => {
        // Reset modal
        if (visible) {
            setErrorDisplay('');
            setUploadFile(undefined);
            setFileInfo(null);
            setUploadFileValid(false);
        }
    }, [visible])

    

    useEffect(() => {
        if (uploadResult != null) {
            dispatch(ACTIONS.SET_S3_KEY_FOR_UPLOAD.withPayload(null));
            services.messageService.showSuccessAutoDismissBanner(`${fileInfo.name} successfully uploaded.`, 10000);
            onSubmit();
        }
    }, [uploadResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(uploadError)) {
            setErrorDisplay(uploadError);
        }
    }, [uploadError])

    const onChooseFileButtonClick = () => {
        // @ts-ignore
        hiddenFileInput.current.click();
    }

    const uploadToS3Handler = () => {
        if (uploadFile != null) {
            //@ts-ignore
            const key = `${new Date().getTime()}_${userProfile.user}_${uploadFile.name.replace(' ', '_')}`;
            dispatch(ACTIONS.SET_S3_KEY_FOR_UPLOAD.withPayload(key));
        }
    }

    const handleInputFileChange = (e: any) => {
        setUploadFile(e.target.files[0]);
        const fileName = e.target.files[0].name;
        setFileInfo({
            name: fileName,
            size: e.target.files[0].size,
            lastModifiedDate: String(e.target.files[0].lastModifiedDate)
        });
        if (fileName.substring(fileName.lastIndexOf('.')) == '.xlsx') {
            setUploadFileValid(true);
        } else {
            setUploadFileValid(false);
        }
        e.target.value = null;
    }

    return <Modal className="fileUploadModal"
                onDismiss={onCancel}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onCancel}>Cancel</Button>
                            <Button className="customCOADefinitionUploadButton" 
                                    variant="primary" 
                                    disabled={!uploadFileValid || uploading}
                                    onClick={() => uploadToS3Handler()}
                            >
                                {uploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Upload Period Charges"
            >
            
                <SpaceBetween direction="vertical" size="s">
                    <ColumnLayout columns={2}>
                        <FormField
                            description="Upload Period Charges in .xlsx format."
                            label="Select file to upload"
                        >
                            <SpaceBetween direction="vertical" size="xs">
                                <Button data-class="fileUploadButton" iconName='upload' iconAlign='left' onClick={onChooseFileButtonClick}>Choose File</Button>
                                <input type='file' ref={hiddenFileInput} accept={'.xlsx'} className='hiddenFileUploadInput' onChange={handleInputFileChange} />
                                <div>
                                    {fileInfo != null && 
                                        <React.Fragment>
                                            <StatusIndicator type={uploadFileValid ? 'success' : 'error'}></StatusIndicator>
                                            <span>{fileInfo.name}</span>
                                        </React.Fragment>
                                    }
                                </div>
                            </SpaceBetween>
                        </FormField>
                    </ColumnLayout>
                <Alert type="error" visible={!StringUtils.isNullOrEmpty(errorDisplay)}>{errorDisplay || ''}</Alert>
                </SpaceBetween>
            </Modal>
}