import {FilterOption, FilterProperty} from "src/models/reports/GetTPReportFilters";
import {PropertyFilterProps} from "@amzn/awsui-components-react";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import React from "react";

type ComponentProps = {
    filterProperties: FilterProperty[];
    filterOptions: FilterOption[];
    filtersDataLoading: boolean;
    query: PropertyFilterProps.Query;
    setQuery: (query: PropertyFilterProps.Query) => void;
}

const I18_CONSTANTS = {
    clearFiltersText: "Clear filters",
    groupPropertiesText: "Properties",
    operationAndText: "and",
    operationOrText: "or",
    editTokenHeader: "Edit filter",
    propertyText: "Property",
    operatorText: "Operator",
    valueText: "Value",
    cancelActionText: "Cancel",
    applyActionText: "Apply",
    enteredTextLabel: (text: any) => `Use: "${text}"`,
};

export default function TPPropertyFilter(props: ComponentProps) {
    return (
        <PropertyFilter
            query={props.query}
            onChange={({detail}) => props.setQuery(detail)}
            filteringStatusType={props.filtersDataLoading ? "loading" : "finished"}
            expandToViewport
            filteringAriaLabel="Search or Filter"
            filteringOptions={props.filterOptions}
            filteringPlaceholder="Search or Filter"
            filteringProperties={props.filterProperties.map((property: FilterProperty): PropertyFilterProps.FilteringProperty => ({
                key: property.key,
                operators: property.operators,
                propertyLabel: property.key,
                groupValuesLabel: property.key + " Values",
            }))}
            i18nStrings={I18_CONSTANTS}
            disabled={props.filtersDataLoading || props.filterOptions === null || props.filterOptions.length === 0}
        />
    )
}