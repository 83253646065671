import {CollectionPreferences} from "@amzn/awsui-components-react";
import React from "react";

type ComponentProps = {
    fetchReportResponse: any;
    fetchReportLoading: boolean;
    setPreferences: (preferences: any) => void;
    preferences: any;
    options: number[];
}

export default function (props: ComponentProps) {
    return (
        <CollectionPreferences
            onConfirm={({detail}) => props.setPreferences({
                pageSize: detail.pageSize as number
            })}
            preferences={props.preferences}
            pageSizePreference={{
                title: "Page Size",
                options: props.options.map((option) => ({
                    value: option,
                    label: `${option} Columns`
                }))
            }}
            confirmLabel={"Apply"}
            cancelLabel={"Cancel"}
            title={"Preferences"}
            disabled={props.fetchReportResponse === null || props.fetchReportLoading}
        />
    )
}