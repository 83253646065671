import CONSTANTS from 'src/utils/constants';

export interface PeriodChargesState {
    availableYears: Array<string>,
    availableAssignees: Array<string>,
    availableStatuses: Array<string>,
    selectedYear?: string,
    selectedAssignee?: string,
    searchCLI?:string,
    selectedStatus?: string,
    searchPayload?: any,
    pageSize: number,
    currentPage: number,
    periodCharges: Array<any>,
    selectedPeriodCharges: Array<any>,
    cliBalances: Array<any>,
    searchPeriodChargesIsLoading: boolean,
    searchPeriodChargesError?: string,
    balanceAdjustmentIsSaving: boolean,
    balanceAdjustmentError?: string,
    saveAdjustmentResponse?: any,
    exportPeriodChargesStatus?: string,
    exportPeriodChargesFileURL?: string,
    s3KeyForUpload?: string,
    showUploadPeriodCharges: boolean
}

export const initialState: PeriodChargesState = {
    availableYears: [],
    availableAssignees: [],
    availableStatuses: [],
    pageSize: 20,
    currentPage: 1,
    periodCharges: [],
    selectedPeriodCharges: [],
    cliBalances: [],
    searchPeriodChargesIsLoading: false,
    balanceAdjustmentIsSaving: false,
    showUploadPeriodCharges: false
}