import { useEffect, useState } from "react";
import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";

export default class CLIService {

    getCLIs(query: string, status?: string, workbookId?: string, calculationStatus?: string) : [string[], boolean, string] {
        const [clis, setCLIs] = useState<string[]>([]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCLIs(status, workbookId, calculationStatus);
                    const json = await response.json();
                    setCLIs(json.clis.map((cli: string) => cli.toUpperCase()));
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData();
            }
        }, [query]);

        return [clis, loading, error];
    }
}