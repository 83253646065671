import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import React from "react";
import {handlePageChange} from "src/components/reports/TPMultiCLIReportsShared";
import {PaginationConfig} from "src/models/reports/FetchReport";

type ComponentProps = {
    currentPageIndex: number,
    pagesCount: number,
    pageSize: number,
    disabled: boolean
    setPageConfig: (config: PaginationConfig) => void
}

export default function (props: ComponentProps) {
    return (
        <Pagination currentPageIndex={props.currentPageIndex} pagesCount={props.pagesCount}
                    onNextPageClick={({detail}) => handlePageChange(detail.requestedPageIndex, props.pageSize, props.setPageConfig)}
                    onChange={({detail}) => handlePageChange(detail.currentPageIndex, props.pageSize, props.setPageConfig)}
                    onPreviousPageClick={({detail}) => handlePageChange(detail.requestedPageIndex, props.pageSize, props.setPageConfig)}
                    disabled={props.disabled}
        />
    )
}