import { Box, Button, ButtonDropdown, ColumnLayout, Container, SpaceBetween, StatusIndicator, TextFilter } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import TPEAction from 'src/models/common/TPEAction';
import { TPELoadingSpinner } from '../../shared/TPELoadingSpinner';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { MECStatusState, initialState } from 'src/services/mec/status/MECStatusState';
import { mecStatusReducer, ACTIONS } from 'src/services/mec/status/MECStatusReducer';
import '../MEC.scss';
import StatusGrid from './StatusGrid';
import CLIDetailsGrid from './CLIDetailsGrid';
import CONSTANTS, {REVERSAL_ALLOWED_EXECUTION_STATUSES} from "src/utils/constants";
import ATPHorizontalRadioGroup from 'src/components/shared/ATPHorizontalRadioGroup';
import ReversalsContainer from './ReversalsContainer';
import ReversalAutosuggest from './ReversalAutosuggest';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import ArrayUtils from 'src/utils/arrayUtils';
import { MECStatusDataRow } from "src/models/mec/status/GetMECStatusDataResult";
import { formatMECStatusDataResults } from "src/services/mec/MECService";
import GetMECCLIDetailsResult, {MECCLIDetailsRow} from "src/models/mec/status/GetMECCLIDetailsResult";
import {UserProfile} from "src/models/users/UserProfile";
import {GlobalAppContext} from "src/components/App";
import {ExecuteBulkReversalWSMessage, ExecuteCLIWebSocketMessage, ExecuteGroupMessage} from "src/models/mec/status/MECWebSocketMessages";
import moment from 'moment';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import WebSocketApi from 'src/services/WebSocketApi';
import StringUtils from 'src/utils/stringUtils';

export type ContextType = {
    state: MECStatusState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    userProfile: UserProfile,
}

const MECStatusProvider = (props: any) => {
    const { state, dispatch, services, children, userProfile } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services, userProfile
    }), [state, dispatch]);
    return (
        <MECStatusContext.Provider value={providerValue}>
            {children}
        </MECStatusContext.Provider>
    );
}
export const MECStatusContext = React.createContext(null as unknown as ContextType);

enum GridType {
    STATUS = "STATUS",
    CLI = "CLI"
}

enum RunType {
    NONE,
    NORMAL,
    DRY
}


export default function RecordsView(props: {services: ServiceCollection, currentPeriod: string, handOffAvailable: boolean, reloadParent: any}) {
    const { services, currentPeriod, handOffAvailable, reloadParent } = props;
    const [state, dispatch] = useReducerWithLogger(mecStatusReducer, initialState);
    const { group, reversals = [], reversalSearchItems = [], statusResult } = state;
    const { userProfile } = React.useContext(GlobalAppContext);
    const { canEdit, canBulkReverse, canHandOff } = getPermissions(AppModules.MEC);

    const reversalSearchOptions = [
        { value: CONSTANTS.REVERSAL_SEARCH_OPTIONS.WORKBOOK, label: CONSTANTS.REVERSAL_SEARCH_OPTIONS.WORKBOOK },
        { value: CONSTANTS.REVERSAL_SEARCH_OPTIONS.CLI, label: CONSTANTS.REVERSAL_SEARCH_OPTIONS.CLI }];

    const [period, setPeriod] = useState('');
    const [statusSearchText, setStatusSearchText] = useState('');
    const [cliSearchText, setCLISearchText] = useState('');
    const [selectedReversalSearchOption, setSelectedReversalSearchOption] = useState(CONSTANTS.REVERSAL_SEARCH_OPTIONS.WORKBOOK);
    const [reversalSearchText, setReversalSearchText] = useState(null as unknown as string);
    const [reversalSearchDisplayText, setReversalSearchDisplayText] = useState(null as unknown as string);
    const [loadTime, setLoadTime] = useState((new Date()).toString());
    const [statusLoadTime, setStatusLoadTime] = useState((new Date()).toString());
    const [triggerSearchForReversal, setTriggerSearchForReversal] = useState(null as unknown as string);
    const [statusActionButtonIsDisabled, setStatusActionButtonIsDisabled] = useState(true);
    const [selectedGroups, setSelectedGroups] = useState<MECStatusDataRow[]>([]);
    const [selectedCLIs, setSelectedCLIs] = useState<MECCLIDetailsRow[]>([]);
    const [statusSearchCount, setStatusSearchCount] = useState(0);
    const [cliDetailsSearchCount, setCliDetailsSearchCount] = useState(0);
    const [showCLIGrid, setShowCLIGrid] = useState(false);
    const [cliLoadTime, setCliLoadTime] = useState(null as unknown as string);
    const [lastGLLoadTime, setLastGLLoadTime] = useState('initial');
    const [viewMode, setViewMode] = useState(CONSTANTS.VIEW_MODE.EDITABLE);
    const [selectedPeriod, setSelectedPeriod] = useState(null as unknown as string);

    const [runTypeTriggered, setRunTypeTriggered] = useState(RunType.NONE);
    const [confirmExecutionModalType, setConfirmExecutionModalType] = useState(null as string | null);
    const [handOffPeriod, setHandOffPeriod] = useState(null as string | null);
    const [handOffModalVisible, setHandOffModalVisible] = useState(false);
    const [reverseTriggered, setReverseTriggered] = useState(false);
    const [gridForAction, setGridForAction] = useState(null as unknown as GridType);

    const getExecutionPeriods = () => {
        const executionPeriods: any[] = [];
        executionPeriods.push({ id: CONSTANTS.CLEAR_SELECTION, text: CONSTANTS.CLEAR_SELECTION });
        if (StringUtils.isNullOrEmpty(currentPeriod)) {
            return executionPeriods;
        }
        const moment = extendMoment(Moment);
        const endDate = moment(currentPeriod, CONSTANTS.PERIOD_VALUE_FORMAT).subtract(1, 'months').date(1);
        Array.from(moment.rangeFromInterval('month', -11, endDate).by('month')).reverse().forEach(d => {
            const formattedDate = d.format('MMM-YYYY').toUpperCase();
            executionPeriods.push({ id: formattedDate, text: formattedDate });
        });
        return executionPeriods;
    }

    const executionPeriods = useMemo(getExecutionPeriods, [currentPeriod]);

    const [newPeriod, isHandingOff, handOffError] = services.mecService.handOff(handOffPeriod);
    const [mecStatusResult, groupExecutionStatus, isGettingMECStatus, mecStatusError] = services.mecService.getMECStatusData(statusLoadTime, period);
    const [cliDetailsResult, isGettingCLIDetails, cliDetailsError] = services.mecService.getGroupExecutionCLIDetails(cliLoadTime, group, period);
    const [reversalsResult, isGettingReversals, reversalsError] = services.mecService.getBulkReversals(statusLoadTime, period);
    const [clisResult, isGettingCLIs, cliError] = services.cliService.getCLIs(loadTime);
    const [workbooksResult, isGettingWorkbooks, workbooksError] = services.mecService.getWorkbooks(loadTime);
    const [searchItemForReversalResult, isSearchingItemForReversal, searchItemForReversalError] = services.mecService.searchItemForReversal(
        triggerSearchForReversal,
        period,
        selectedReversalSearchOption == CONSTANTS.REVERSAL_SEARCH_OPTIONS.WORKBOOK ? reversalSearchText : undefined,
        selectedReversalSearchOption == CONSTANTS.REVERSAL_SEARCH_OPTIONS.CLI ? reversalSearchText : undefined);
    const [lastGLDataIngestionTimestamp, isGettingTimestamp, timestampError] = services.mecService.getLastGLDataIngestionTimestamp(lastGLLoadTime);

    //TODO: will change once search groups API is completed
    const searchGroups = (searchText:string) => {
        const search = searchText.toLowerCase();
        const statusSearchRows = groupExecutionStatus.filter(record => record.group.toLowerCase().startsWith(search));
        const results = formatMECStatusDataResults(statusSearchRows);

        console.log(results);

        dispatch(ACTIONS.SET_STATUS_RESULT.withPayload(results))
        setStatusSearchCount(statusSearchRows.length);

        if(searchText.length == 0){
            dispatch(ACTIONS.SET_STATUS_RESULT.withPayload(mecStatusResult));
            setStatusSearchCount(mecStatusResult.records.length);
        }
    }

    //TODO: will change once search cliDetails API is completed
    const searchCLIs = (searchText:string) => {
        const search = searchText.toLowerCase();
        const cliSearchRows = cliDetailsResult.cliDetails
            .filter(cliDetail => cliDetail.calculationNumber.toLowerCase().startsWith(search));
        const results: GetMECCLIDetailsResult = {
            cliDetails: cliSearchRows
        }

        dispatch(ACTIONS.SET_CLI_DETAILS_RESULT.withPayload(results));
        setCliDetailsSearchCount(cliSearchRows.length);

        if(searchText.length == 0){
            dispatch(ACTIONS.SET_CLI_DETAILS_RESULT.withPayload(cliDetailsResult));
            setCliDetailsSearchCount(cliDetailsResult.cliDetails.length);
        }
    }

    useEffect(() => {
        setPeriod(currentPeriod)
    }, [currentPeriod])

    useEffect(() => {
        reloadParent((new Date()).toString());
    }, [newPeriod])

    useEffect(() => {
        if (!showCLIGrid) {
            return;
        }
        var element = document.querySelector('#cliContainer');
        // scroll to element
        element?.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }, [showCLIGrid])

    useEffect(() => {
        setPeriod(selectedPeriod == null ? currentPeriod : moment(selectedPeriod).format(CONSTANTS.MEC_PERIOD_FORMAT));
        setStatusLoadTime((new Date()).toString());
        setCliLoadTime((new Date()).toString());
        setViewMode(selectedPeriod == null ? CONSTANTS.VIEW_MODE.EDITABLE : CONSTANTS.VIEW_MODE.FROZEN);
    }, [selectedPeriod])

    useEffect(() => {
        const webSocketListener = setupWebSocketEvents(services, refreshStatus, setReversalActionState, setRunActionState);
        return () => {
            services.atpWebSocketApiService.removeListener('', webSocketListener);
        }
    }, [])

    useEffect(() => {
        if (statusResult != null) {
            const selectedGroups = statusResult.records.filter(record => record.isSelected);
            if (selectedGroups.length > 0) {
                setStatusActionButtonIsDisabled(false);
            } else {
                setStatusActionButtonIsDisabled(true);
            }
            setSelectedGroups(selectedGroups);
        }
    }, [statusResult]);

    useEffect(() => {
        setStatusSearchCount(mecStatusResult == null ? 0 : mecStatusResult.records.length);
        dispatch(ACTIONS.SET_STATUS_RESULT.withPayload(mecStatusResult));
    }, [mecStatusResult]);

    useEffect(() => {
        if (cliDetailsResult && cliDetailsResult.cliDetails.length > 0) {
            dispatch(ACTIONS.SET_CLI_DETAILS_RESULT.withPayload(cliDetailsResult));
            setShowCLIGrid(true);
        }else{
            setShowCLIGrid(false);
        }
    }, [cliDetailsResult]);

    useEffect(() => {
        if (reversalsResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_REVERSALS.withPayload(reversalsResult));
    }, [reversalsResult])

    useEffect(() => {
        if (searchItemForReversalResult == null) {
            return;
        }
        dispatch(ACTIONS.ADD_REVERSAL_SEARCH_ITEM.withPayload(searchItemForReversalResult));
        services.messageService.showSuccessAutoDismissBanner(`${reversalSearchDisplayText} has been successfully added to the reversals table below. If you do not reverse the item, it will be removed from the table when you leave this page.`, 10000);
    }, [searchItemForReversalResult])

    const searchForReversal = (text: string) => {
        if (text == null || text.trim() == '') {
            return;
        }

        if (selectedReversalSearchOption == CONSTANTS.REVERSAL_SEARCH_OPTIONS.WORKBOOK) {
            const workbookId = text.split(':')[0];
            const workbookName = text.replace(`${workbookId}: `, '');

            if (reversalSearchItems.concat(reversals).filter(r => r.workbookId == workbookId).length > 0) {
                services.messageService.showInfoAutoDismissBanner(`${workbookName} is already available in the reversals table below.`, 10000);
                return;
            }

            if (ArrayUtils.isNullOrEmpty(workbooksResult?.filter(w => w.workbookId == workbookId))) {
                services.messageService.showErrorBanner(`Cannot find workbook named ${text}. Please select an option from the workbook list.`);
                return;
            }
            setReversalSearchText(workbookId);
            setReversalSearchDisplayText(workbookName);
        } else {
            if (reversalSearchItems.concat(reversals).filter(r => r.calculationNumber == text).length > 0) {
                services.messageService.showInfoAutoDismissBanner(`${text} is already available in the reversals table below.`, 10000);
                return;
            }

            if (ArrayUtils.isNullOrEmpty(clisResult?.filter(c => c == text))) {
                services.messageService.showErrorBanner(`Cannot find CLI ${text}. Please select an option from the CLI list.`);
                return;
            }
            setReversalSearchText(text);
            setReversalSearchDisplayText(text);
        }
        setTriggerSearchForReversal((new Date()).toString());
    }

    const onSelectCLI = () => {
        setSelectedCLIs(cliDetailsResult.cliDetails.filter(r => r.isSelected));
    }

    const onGroupRun = (runType:RunType) => {
        const isDryRun = runType === RunType.DRY;
        if (selectedGroups.length != 0) {
            setRunActionState(GridType.STATUS, runType);
            selectedGroups.forEach(group => {
                const groupNum = group.dayOrGroup.split(" ");
                services.atpWebSocketApiService.executeGroup({
                    groupNumber: groupNum.length > 1 ? groupNum[1] : group.dayOrGroup, dryRun: isDryRun, executedBy : userProfile.user
                })
            });
        }
    }

    const handleExecutionModalConfirmed = () => {
        if (confirmExecutionModalType === GridType.CLI.toString()) {
            onCLIRun(RunType.NORMAL);
        }
        else {
            onGroupRun(RunType.NORMAL);
        }
        setConfirmExecutionModalType(null);
    }
    
    const onCLIRun = (runType:RunType) => {
        if (selectedCLIs.length != 0) {
            const isDryRun = runType === RunType.DRY;
            setRunActionState(GridType.CLI, runType);
            selectedCLIs.forEach(cli => {
                services.atpWebSocketApiService.executeCLI({
                    agreementCLI: cli.calculationNumber, dryRun: isDryRun, executedBy : userProfile.user
                })
            });
            services.messageService.showSuccessAutoDismissBanner(`${selectedCLIs.length} CLI(s) submitted for ${isDryRun? 'dry run ':''}execution.`, 3000);
        }
    }

    const onReverse = (itemType: string) => {
        if (itemType == CONSTANTS.REVERSAL_ITEM_TYPES.GROUP) {
            const validGroups = selectedGroups
                .filter(x => REVERSAL_ALLOWED_EXECUTION_STATUSES.includes(x.status))
            const ineligibleGroupCount = selectedGroups.length - validGroups.length;
            if (validGroups.length > 0) {
                setReversalActionState(GridType.STATUS, true);
            }
            executeReversal(validGroups, itemType, 'dayOrGroup', ineligibleGroupCount);
        } else if (itemType == CONSTANTS.REVERSAL_ITEM_TYPES.CLI) {
            const validCLIs = selectedCLIs.filter(cli => REVERSAL_ALLOWED_EXECUTION_STATUSES.includes(cli.status));
            const ineligibleCLICount = selectedCLIs.length - validCLIs.length;
            if (validCLIs.length > 0) {
                setReversalActionState(GridType.CLI, true);
            }
            executeReversal(validCLIs, itemType, 'calculationNumber', ineligibleCLICount);
        }
    }

    const onHandOff = () => {
        setHandOffPeriod(currentPeriod);
        setHandOffModalVisible(false);
    }

    const refreshLastGLDataIngestionTimestamp = () => {
        setLastGLLoadTime((new Date()).toString());
    }

    const executeReversal = (items: any[], itemType: string, itemIdKey: string, ineligibleItemCount: number) => {
        if (items.length > 0) {
            const reversalRequests = items.map(item => ({
                itemId: itemType?.toUpperCase() == CONSTANTS.REVERSAL_ITEM_TYPES.GROUP.toUpperCase() ? item[itemIdKey]?.split(" ")[1] : item[itemIdKey],
                itemType: itemType.toUpperCase()
            }))

            services.atpWebSocketApiService.executeBulkReversal({
                requests: reversalRequests,
                reversedBy: userProfile.user
            });
            refreshStatus();

            if (ineligibleItemCount == 0) {
                services.messageService.showSuccessAutoDismissBanner(`${items.length} items submitted for reversal.`, 3000);
            } else {
                services.messageService.showWarningBanner(`${items.length} items submitted for reversal, ${ineligibleItemCount} items ineligible for reversal.`);
            }
        } else {
            services.messageService.showErrorBanner('None of the selected items are eligible for reversal.')
        }
    }

    const refreshStatus = () => {
        setStatusLoadTime(new Date().toString());
        refreshLastGLDataIngestionTimestamp();
        if (showCLIGrid) {
            setCliLoadTime((new Date()).toString());
        }
    }

    const setReversalActionState = (gridType: GridType | null, reverseTriggered: boolean) => {
        setGridForAction(gridType as unknown as GridType);
        setReverseTriggered(reverseTriggered);
    }

    const setRunActionState = (gridType: GridType | null, runTypeTriggered: RunType) => {
        setGridForAction(gridType as unknown as GridType);
        setRunTypeTriggered(runTypeTriggered);
    }

    const formatCurrentPeriod = () : string => {
        return moment(currentPeriod, CONSTANTS.PERIOD_VALUE_FORMAT).format(CONSTANTS.PERIOD_DISPLAY_FORMAT).toUpperCase();
    }

    return <MECStatusProvider services={services} state={state} dispatch={dispatch} userProfile = {userProfile}>
        <SpaceBetween direction="vertical" size="m">
            <div className="mecStatusActionBar">
                <div className="reversalSearchContainer">
                    { viewMode == CONSTANTS.VIEW_MODE.EDITABLE && canBulkReverse ?
                        <React.Fragment>
                            <ATPHorizontalRadioGroup
                                data-class="basicModalRadioGroup"
                                onChange={setSelectedReversalSearchOption}
                                value={selectedReversalSearchOption}
                                items={reversalSearchOptions}
                            />
                            <ReversalAutosuggest
                                filterType={selectedReversalSearchOption}
                                workbooks={workbooksResult}
                                clis={clisResult}
                                onSelect={searchForReversal}
                                loading={isGettingWorkbooks || isGettingCLIs}
                                disabled={isGettingWorkbooks || isGettingCLIs || isSearchingItemForReversal}
                            />
                            {(isGettingWorkbooks || isGettingCLIs || isSearchingItemForReversal) &&
                                <Box padding={{left: 'xs', top: 'xxs'}}>
                                    <StatusIndicator data-class="smallStatusIndicator" type="loading">
                                        {isSearchingItemForReversal ? 'Searching...' : 'Loading options...'}
                                    </StatusIndicator>
                                </Box>
                            }
                        </React.Fragment>
                    :
                        ''
                    }
                </div>
                <div>
                    <Box float="right">
                        <SpaceBetween size="m" direction="horizontal">
                            {viewMode == CONSTANTS.VIEW_MODE.FROZEN &&
                                <div className="subtleAlert mecAlert">
                                    <StatusIndicator data-class="alertText" type="info">
                                        Viewing MEC execution results for {selectedPeriod}. This page is read only.
                                    </StatusIndicator>
                                </div>
                            }
                            <ButtonDropdown
                                className="polarisSmallButtonDropdown"
                                disabled={isGettingMECStatus || isGettingCLIDetails || isGettingReversals}
                                items={executionPeriods}
                                onItemClick={({ detail }) => setSelectedPeriod(detail.id == CONSTANTS.CLEAR_SELECTION ? null as unknown as string : detail.id)}
                            >
                                {selectedPeriod == null ? 'View past MEC execution results' : selectedPeriod}
                            </ButtonDropdown>
                            <div className="timestampContainer">
                                <span className="smallTextSpan"><b>GL data ingestion timestamp: </b></span>
                                    <span className="smallTextSpan timestampSpan">{isGettingTimestamp ? 
                                        <StatusIndicator data-class="smallStatusIndicator" type="loading"/> 
                                    : 
                                        lastGLDataIngestionTimestamp}
                                </span>
                                <Button data-class="smallIconButton" 
                                    iconName="refresh" 
                                    variant="inline-icon" 
                                    onClick={refreshLastGLDataIngestionTimestamp} 
                                    disabled={isGettingTimestamp}/>
                            </div>
                            <Button data-class="smallSecondaryButton" 
                                variant="normal" 
                                onClick={refreshStatus} 
                                iconName="refresh"
                                disabled={isGettingMECStatus || isGettingCLIDetails || isGettingReversals || isGettingTimestamp}>
                                    Refresh page
                                </Button>
                        </SpaceBetween>
                    </Box>
                </div>
            </div>
            {(reversals.length > 0 || (viewMode == CONSTANTS.VIEW_MODE.EDITABLE && reversalSearchItems.length > 0)) && 
                <ReversalsContainer loading={isGettingReversals} services={services} userId={userProfile.user} viewMode={viewMode} reverseTriggered={reverseTriggered} 
                    refreshStatus={refreshStatus} setReversalActionState={setReversalActionState} setTriggerGroupDetails={setCliLoadTime}/>}
            <Container data-class="containerWithHeaderAndGrid">
                <div className="containerHeader">
                    <ColumnLayout columns={2} data-class="fullColumnLayout">
                        <Box variant="h2">Status</Box>
                        <Box float="right">
                            { viewMode == CONSTANTS.VIEW_MODE.EDITABLE && canEdit ? 
                                <SpaceBetween direction="horizontal" size="s">
                                    { canHandOff && <Button variant="normal"
                                        disabled={!handOffAvailable || runTypeTriggered !== RunType.NONE}
                                        onClick={() => setHandOffModalVisible(true)}>
                                            {isHandingOff ?
                                                <StatusIndicator type="loading">Handing Off...</StatusIndicator>
                                            :
                                                'Hand Off'
                                            }
                                    </Button> }
                                    { canBulkReverse && <Button variant="normal" 
                                        disabled={!handOffAvailable || statusActionButtonIsDisabled || reverseTriggered}
                                        onClick={() => onReverse(CONSTANTS.REVERSAL_ITEM_TYPES.GROUP)}>
                                            {reverseTriggered && gridForAction == GridType.STATUS ?
                                                <StatusIndicator type="loading">Reversing...</StatusIndicator>
                                            :
                                                'Reverse'
                                            }
                                        </Button> }
                                    <Button variant="normal" disabled={true}>Pause</Button>
                                    <Button variant="normal" 
                                        disabled={!handOffAvailable || statusActionButtonIsDisabled || runTypeTriggered !== RunType.NONE}
                                        onClick={() => setConfirmExecutionModalType("Group")}>
                                            {runTypeTriggered === RunType.NORMAL && gridForAction == GridType.STATUS ?
                                                <StatusIndicator type="loading">Running...</StatusIndicator>
                                            :
                                                'Run'
                                            }
                                        </Button>
                                        <Button variant="normal"
                                        disabled={statusActionButtonIsDisabled || runTypeTriggered !== RunType.NONE}
                                        onClick={() => onGroupRun(RunType.DRY)}>
                                            {runTypeTriggered === RunType.DRY && gridForAction == GridType.STATUS ?
                                                <StatusIndicator type="loading">Dry Running...</StatusIndicator>
                                            :
                                                'Dry Run'
                                            }
                                        </Button>
                                </SpaceBetween>
                            :
                                ''
                            }
                        </Box>
                        <TextFilter data-class="basicTextFilter"
                            filteringText={statusSearchText}
                            onDelayedChange={({ detail }) => searchGroups(detail.filteringText)}
                            onChange={({ detail }) => setStatusSearchText(detail.filteringText)}
                            filteringPlaceholder='Search'
                            filteringAriaLabel='Search'
                            countText= {statusSearchCount + ' matches found'}
                        />
                    </ColumnLayout>
                </div>
                <TPELoadingSpinner loading={isGettingMECStatus} loadingText='Loading'>
                    <div className="mecTableContainer">
                        <StatusGrid loading={isGettingMECStatus} setTriggerGroupDetails={setCliLoadTime}/>
                    </div>
                </TPELoadingSpinner>
            </Container>
            {showCLIGrid && <Container data-class="containerWithHeaderAndGrid">
                <div className="containerHeader" id="cliContainer">
                    <ColumnLayout columns={2} data-class="fullColumnLayout">
                        <Box variant="h2">{'CLI Details' + (group ? ' - ' + group : '')}</Box>
                        <Box float="right">
                            { viewMode == CONSTANTS.VIEW_MODE.EDITABLE && canEdit ?
                                <SpaceBetween direction="horizontal" size="s">
                                    { canBulkReverse && <Button variant="normal" 
                                        disabled={!handOffAvailable || selectedCLIs.length == 0 || reverseTriggered}
                                        onClick={() => onReverse(CONSTANTS.REVERSAL_ITEM_TYPES.CLI)}>
                                            {reverseTriggered && gridForAction == GridType.CLI ?
                                                <StatusIndicator type="loading">Reversing...</StatusIndicator>
                                            :
                                                'Reverse'
                                            }
                                    </Button> }
                                    <Button variant="normal" disabled={true}>Stop from running</Button>
                                    <Button variant="normal" 
                                        disabled={!handOffAvailable || selectedCLIs.length == 0 || runTypeTriggered !== RunType.NONE}
                                        onClick={() => setConfirmExecutionModalType("CLI")}>
                                            {runTypeTriggered === RunType.NORMAL && gridForAction == GridType.CLI ?
                                                <StatusIndicator type="loading">Running...</StatusIndicator>
                                            :
                                                'Run'
                                            }
                                    </Button>
                                    <Button variant="normal"
                                        disabled={selectedCLIs.length == 0 || runTypeTriggered !== RunType.NONE}
                                        onClick={() => onCLIRun(RunType.DRY)}>
                                            {runTypeTriggered === RunType.DRY && gridForAction == GridType.CLI ?
                                                <StatusIndicator type="loading">Dry Running...</StatusIndicator>
                                            :
                                                'Dry Run'
                                            }
                                    </Button>
                                </SpaceBetween>
                            :
                                ''
                            }
                        </Box>
                        <TextFilter data-class="basicTextFilter"
                            filteringText={cliSearchText}
                            onDelayedChange={({ detail }) => searchCLIs(detail.filteringText)}
                            onChange={({ detail }) => setCLISearchText(detail.filteringText)}
                            filteringPlaceholder='Search'
                            filteringAriaLabel='Search'
                            countText= {cliDetailsSearchCount + ' matches found'}
                        />
                    </ColumnLayout>
                </div>
                <div className="tableContainer">
                    <CLIDetailsGrid loading={isGettingCLIDetails} onCLISelect={onSelectCLI} />
                </div>
            </Container>}
        </SpaceBetween>
        <TPEBasicModal
            className="confirmExecutionModal"
            visible={confirmExecutionModalType != null}
            action={ACTION_TYPE.CONFIRM_CANCEL}
            title={`Confirm ${confirmExecutionModalType} Execution`}
            onCancel={() => setConfirmExecutionModalType(null)}
            onConfirm={handleExecutionModalConfirmed}
        >
            Please confirm you want to run these {confirmExecutionModalType}(s).
        </TPEBasicModal>
        <TPEBasicModal
            className="confirmExecutionModal"
            visible={handOffModalVisible}
            action={ACTION_TYPE.CONFIRM_CANCEL}
            title={"Confirm MEC Hand Off"}
            onCancel={() => setHandOffModalVisible(false)}
            onConfirm={() => onHandOff()}
        >
            Please confirm you want to close period {formatCurrentPeriod()}.
        </TPEBasicModal>
        <TPEErrorWatcher services={services} errors={[mecStatusError, cliDetailsError, reversalsError, searchItemForReversalError, 
            cliError, workbooksError, timestampError, handOffError]} />
    </MECStatusProvider>
}

/**
 * Appends a listener for the execute group (Web Socket) messages. It checks the messages and
 * displays the error if any.
 * @param services The list of services
 */
function setupWebSocketEvents(
    services: ServiceCollection, 
    refreshStatus: () => any, 
    setReverseActionState: (gridType: GridType | null, refreshTriggered: boolean) => any, 
    setRunActionState: (gridType: GridType | null, runTypeTriggered: RunType) => any
    ) {

    const listener = (x: any) => {        
        const [parsingWasSucessful, webSocketMessage] = tryParseJSON(x);
        if (!parsingWasSucessful){
            services.messageService.showErrorBanner(`There was an error performing this action: ${x}`);
            setRunActionState(null, RunType.NONE);
            return;
        }

        //Listen for messages from bulk reversal API
        if (webSocketMessage?.messageType == CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_BULK_REVERSAL) {
            const executeBulkReversalWSMessage = webSocketMessage as ExecuteBulkReversalWSMessage;
            const invalidItemCount = Object.keys(executeBulkReversalWSMessage.invalidItems).length;
            if (invalidItemCount > 0) {
                services.messageService.showErrorBanner(`Cannot reverse ${invalidItemCount} items: Items either have not been executed for current MEC period or statuses are not valid for reversal.`);
            }
            setReverseActionState(null, false);
        }else if(webSocketMessage?.messageType == CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_CLI) {
            const executeCLIWSMessage = webSocketMessage as ExecuteCLIWebSocketMessage;
            refreshStatus();
            setRunActionState(null, RunType.NONE);
        } else if (webSocketMessage?.messageType == CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_GROUP) {
            const executeGroupWSMessage = webSocketMessage as ExecuteGroupMessage;
            if (executeGroupWSMessage.status == CONSTANTS.EXECUTION_STATUS.PENDING) {
                services.messageService.showSuccessAutoDismissBanner(`${executeGroupWSMessage.isDryRun? 'Dry run e':'E'}xecution of group ${executeGroupWSMessage.groupNumber} started.`, 3000);
                refreshStatus();
            } else {
                // TODO: implement a status field in the backend for error messages
                services.messageService.showErrorBanner(x);
            }
            setRunActionState(null, RunType.NONE);
        }
    };
    const errorHandler = (x:any) => {
        services.messageService.showErrorBanner("There was a problem connecting to the web socket API. Please contact technical support.");
        setTimeout(() => {
            setReverseActionState(null, false);
            setRunActionState(null, RunType.NONE);
        }, 500);
    }
    services.atpWebSocketApiService.addListener(listener);
    services.atpWebSocketApiService.addKeyListener(WebSocketApi.ConnectionEvent.ON_ERROR, errorHandler);
    return listener;
}

function tryParseJSON(x:any): [boolean,any|null]{
    try {
        return [true, JSON.parse(x)];
    }
    catch(e:any){
        return [false, null];
    }
}