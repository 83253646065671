import React, { useState } from "react";
import { Autosuggest } from "@amzn/awsui-components-react";

export default function ATPCLIsAutosuggest(props: { className?: string, 
    clis: string[] | undefined,
    initialValue: string,
    onCLISelected: (value: string) => any,
    onAutocompleteTextChanged: (value: string) => any
}) {
    const { className, clis, initialValue, onCLISelected, onAutocompleteTextChanged } = props;
    const [options, setOptions] = useState([] as any[]);
    const [value, setValue] = useState(initialValue);
    const [previousEvent, setPreviousEvent] = useState(null as any);

    // Refreshing options after we get new CLIs list
    React.useEffect(() => {
        if (previousEvent){
            handleLoadItems(previousEvent);
        }
    },[clis])

    const handleLoadItems = (event: any) => {
        setPreviousEvent(event);
        let { filteringText } = event.detail;

        if (filteringText == null || filteringText == '') {
            return;
        }

        const filteredOptions = clis?.filter(
            x => x.toLowerCase().includes(filteringText.toLowerCase())
        ).map((val) => { return {value: val} }) || [];
        setOptions([...filteredOptions]);
    }

    const handleChange = (selectedValue: any) => {
        setValue(selectedValue);
        onAutocompleteTextChanged(selectedValue);
    }

    const handleBlur = () => {
        onCLISelected(value);
    }

    
    return (
        <Autosuggest
            data-class={className}
            onChange={({ detail }) => handleChange(detail.value)}
            onBlur={() => handleBlur()}
            onSelect={({ detail }) => onCLISelected(detail.value)}
            value={value}
            options={options}
            enteredTextLabel={val => `Use: "${val}"`}
            placeholder="Enter full or part of CLI"
            empty="No matches found"
            loadingText="Loading"
            onLoadItems={handleLoadItems}
            filteringType="manual"
            statusType={clis == undefined ? 'loading' : 'finished'}
            virtualScroll={true}
        />
    )
}