import React, { useContext, useEffect, useMemo, useState } from 'react';
import 'src/assets/styles/react-table.scss';
import TPESelectableReactTable from 'src/components/shared/TPESelectableReactTable';
import { MECStatusContext } from './StatusView';
import CONSTANTS from 'src/utils/constants';
import ExecutionStatusIndicator from "src/components/shared/ExecutionStatusIndicator";
import {Reversal} from "src/models/mec/status/Reversal";
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import { SortingRule } from 'react-table';
import { MECCLIDetailsRow } from 'src/models/mec/status/GetMECCLIDetailsResult';
import {Link} from "@amzn/awsui-components-react";

export default function CLIDetailsGrid(props: {loading: boolean, onCLISelect: () => any}) {
    const { loading , onCLISelect} = props;
    const { state, dispatch } = useContext(MECStatusContext);
    const { cliDetailsResult } = state;
    const { canEdit } = getPermissions(AppModules.MEC);

    const [data, setData] = useState([] as MECCLIDetailsRow[]);
    const [sortChanged, setSortChanged] = useState('');

    const getColDefs = () => {
        const colDefs: any[] = [];
        Object.values(CONSTANTS.MEC_CLI_DETAILS_COLUMN_HEADERS).forEach(column => {
            switch(column.ACCESSOR){
                case CONSTANTS.MEC_CLI_DETAILS_COLUMN_HEADERS.STATUS.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <div className="cell-text"><ExecutionStatusIndicator status={e.value} /></div>
                    });
                    break;
                case CONSTANTS.MEC_CLI_DETAILS_COLUMN_HEADERS.EXECUTION_ERROR.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR
                    });
                    break;
                case CONSTANTS.MEC_CLI_DETAILS_COLUMN_HEADERS.DRY_RUN.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <span className="textCell cell-text-wrap">{e.value? "Yes" : (e.value === false? "No" : "")}</span>
                    });
                    break;
                case CONSTANTS.MEC_CLI_DETAILS_COLUMN_HEADERS.SPURSH_GROUP_ID.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => (e.value != null ?
                                <Link className="cell-link cell-text-wrap" external href={CONSTANTS.ENVIRONMENT_VARIABLES.WHERE_IS_MY_JOURNAL_LINK + e.value}>{e.value}</Link> :
                                <span className="textCell cell-text-wrap">{e.value}</span>
                        )
                    });
                    break;
                default:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        Cell: (e: any) => <div className="text-cell-wrap">{e.value}</div>,
                        accessor: column.ACCESSOR,
                        suppressSorting: column.ACCESSOR != CONSTANTS.MEC_CLI_DETAILS_COLUMN_HEADERS.NAME.ACCESSOR
                    });
                    break;
            }
        });
        return colDefs;
    }

    const colDefs = useMemo(getColDefs, [data, canEdit, sortChanged]);

    useEffect(() => {
        setData(cliDetailsResult.cliDetails);
    }, [cliDetailsResult])

    const onSortBy = function (sortBySpecs: Array<SortingRule<any>>) {
        if (sortBySpecs == null || sortBySpecs.length === 0) {
            return;
        }
        const sortBy = sortBySpecs[0];
        if (sortBy.desc === true) {
            data.sort((a, b) => {
                const aItem = (a as any);
                const bItem = (b as any);
                if (aItem[sortBy.id] || '' > bItem[sortBy.id] || '') {
                    return -1;
                } else if (aItem[sortBy.id] || '' < bItem[sortBy.id] || '') {
                    return 1;
                }
                return 0;
            });
        } else {
            data.sort((a, b) => {
                const aItem = (a as any);
                const bItem = (b as any);
                if (aItem[sortBy.id] || '' < bItem[sortBy.id] || '') {
                    return -1;
                } else if (aItem[sortBy.id] || '' > bItem[sortBy.id] || '') {
                    return 1;
                }
                return 0;
            });
        }
        setSortChanged((new Date()).toString());
    }

    return (
        <div className="mecGrid">
            {cliDetailsResult && <TPESelectableReactTable {...{
                data,
                columnDefinitions: colDefs,
                className: "mecCLIDetailsGrid",
                isSearching: loading,
                editableOptions: null as unknown as any,
                allowMultipleRowSelection: true,
                onRowClicked:(isChecked, reversal: Reversal, index) =>  onCLISelect(),
                hideCheckboxes: !canEdit,
                sortable: true,
                onSortBy
            }} />}
        </div>
    );
}