import {FetchReportResponse, PaginationConfig} from "src/models/reports/FetchReport";

export interface ReportPayload {
    tpTaxReportJSONData: any;
    lastPage: number;
}

/**
 * Handles page change and updates the page configuration
 */
export const handlePageChange = (
    pageNumber: number,
    pageSize: number,
    setPageConfig: (config: PaginationConfig) => void
) => {
    setPageConfig({
        pageNumber,
        pageSize
    });
};

/**
 * Resets the report state to initial values
 */
export const resetReportState = (
    setReportData: (data: any) => void,
    setLastPage: (page: number) => void
) => {
    setReportData(null);
    setLastPage(1);
};

/**
 * Parses the report payload from string to JSON
 */
export const parseReportPayload = (payload: string): ReportPayload => {
    return JSON.parse(payload);
};

/**
 * Validates if the report data contains valid headers
 */
export const isValidReport = (reportData: any): boolean => {
    return reportData?.reportHeaders?.length > 0;
};

/**
 * Handles the report response and updates the state accordingly
 */
export const handleFetchReportResponse = (
    fetchReportResponse: FetchReportResponse | null,
    setReportData: (data: any) => void,
    setLastPage: (page: number) => void,
    showErrorMessage: (message: string) => void
) => {
    if (!fetchReportResponse) {
        return;
    }

    if (!fetchReportResponse.payload) {
        resetReportState(setReportData, setLastPage);
        showErrorMessage(fetchReportResponse.statusMessage);
        return;
    }

    try {
        const payload = parseReportPayload(fetchReportResponse.payload);
        const reportData = payload?.tpTaxReportJSONData;

        if (!reportData || !isValidReport(reportData)) {
            resetReportState(setReportData, setLastPage);
            showErrorMessage("Empty report available, nothing to display");
            return;
        }

        setReportData(reportData);
        setLastPage(payload.lastPage);
    } catch (error) {
        resetReportState(setReportData, setLastPage);
        showErrorMessage("Error parsing report data. Contact Engineering Team.");
    }
};
