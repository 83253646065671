import React from 'react';
import CONSTANTS from 'src/utils/constants';
import ButtonBasedReportHeaderAction from './ButtonBasedReportHeaderAction';
import { REPORT_DETAILS } from './ReportDefinitionConstants';

export function getHeaderActionDetailsForTPTaxReportCard(
    reportDetails: { headerName: string, reportType: string },
    setReportNameAndReportHeader: any,
    setNavigateURL: any,
    setShowTPTaxLEFFilterReportModal: any) {
    switch (reportDetails) {
        case REPORT_DETAILS.TP_TAX_VARIANCE:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => setNavigateURL('/reports/tpTaxVarianceRecords')}
                headerName={reportDetails.headerName}
            />)
        case REPORT_DETAILS.TP_TAX:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => setNavigateURL('/reports/tpTaxReport')}
                headerName={reportDetails.headerName}
            />)
        case REPORT_DETAILS.TP_TAX_CWB_REPORT:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => setNavigateURL('/reports/cwb')}
                headerName={reportDetails.headerName}
            />);
        case REPORT_DETAILS.TP_TAX_LEF_REPORT:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => setNavigateURL('/reports/lef')}
                headerName={reportDetails.headerName}
            />);
        default:
            return <h3>{reportDetails.headerName}</h3>;
    }
}


export function getHeaderActionDetailsForTPAccountingReportCard(
    reportDetails: { headerName: string, reportType: string },
    setReportNameAndReportHeader: any,
    setShowNoFilterReportModal: any,
    setShowPeriodFilterReportModal: any) {
    switch (reportDetails) {
        case REPORT_DETAILS.CALCULATIONS:
        case REPORT_DETAILS.JOURNAL_DETAILS:
        case REPORT_DETAILS.ALLOCATION_TRENDING:
        case REPORT_DETAILS.CUSTOM_DATA_AUTO_INSERT:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => {
                    setReportNameAndReportHeader(reportDetails.headerName, reportDetails.reportType);
                    setShowPeriodFilterReportModal(true);
                }}
                headerName={reportDetails.headerName} />);
        case REPORT_DETAILS.DATASOURCES:
        case REPORT_DETAILS.CUSTOM_COA:
        case REPORT_DETAILS.TAX_AGREEMENT_DETAILS:
        case REPORT_DETAILS.COA_CHANGE_MANAGEMENT:
        case REPORT_DETAILS.FORMULA_SET:
        case REPORT_DETAILS.CALCULATION_CHANGE:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => {
                    setReportNameAndReportHeader(reportDetails.headerName, reportDetails.reportType);
                    setShowNoFilterReportModal(true);
                }}
                headerName={reportDetails.headerName} />);
        case REPORT_DETAILS.TP_TRENDING:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => {
                    setReportNameAndReportHeader(reportDetails.headerName, reportDetails.reportType);
                }}
                headerName={reportDetails.headerName}
                externalLink={CONSTANTS.ENVIRONMENT_VARIABLES.TP_TRENDING_PORTAL_URI}
            />);
        default:
            return <h3>{reportDetails.headerName}</h3>;
    }
}

export function getHeaderActionDetailsForIndirectTaxReportCard(
    reportDetails: { headerName: string, reportType: string },
    setNavigateURL: any) {
    switch (reportDetails) {
        case REPORT_DETAILS.TAX_AUDIT_RECORD:
            return (<ButtonBasedReportHeaderAction
                headerAction={() => setNavigateURL('/reports/taxAuditRecord')}
                headerName={reportDetails.headerName} />);
        default:
            return <h3>{reportDetails.headerName}</h3>;
    }
}
