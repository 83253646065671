import TPEAction from "src/models/common/TPEAction";
import { PeriodChargesState } from "./PeriodChargesState";


export const ACTIONS = Object.freeze({
    SET_AVAILABLE_YEARS: new TPEAction('SET_AVAILABLE_YEARS'),
    SET_AVAILABLE_ASSIGNEES: new TPEAction('SET_AVAILABLE_ASSIGNEES'),
    SET_AVAILABLE_STATUSES: new TPEAction('SET_AVAILABLE_STATUSES'),
    SET_SELECTED_YEAR: new TPEAction('SET_SELECTED_YEAR'),
    SET_SELECTED_ASSIGNEE: new TPEAction('SET_SELECTED_ASSIGNEE'),
    SET_SEARCH_CLI: new TPEAction('SET_SEARCH_CLI'),
    SET_SELECTED_STATUS: new TPEAction('SET_SELECTED_STATUS'),
    SET_SEARCH_PAYLOAD: new TPEAction('SET_SEARCH_PAYLOAD'),
    SET_CURRENT_PAGE: new TPEAction('SET_CURRENT_PAGE'),
    SET_PERIOD_CHARGES: new TPEAction('SET_PERIOD_CHARGES'),
    SET_SELECTED_PERIOD_CHARGES: new TPEAction('SET_SELECTED_PERIOD_CHARGES'),
    SET_FIRST_PERIOD_CHARGE_AS_SELECTED: new TPEAction('SET_FIRST_PERIOD_CHARGE_AS_SELECTED'),
    SET_CLI_BALANCES: new TPEAction('SET_CLI_BALANCES'),
    SET_IS_SEARCHING_PERIOD_CHARGES: new TPEAction('SET_IS_SEARCHING_PERIOD_CHARGES'),
    SET_PERIOD_CHARGES_ERROR: new TPEAction('SET_PERIOD_CHARGES_ERROR'),
    SET_IS_SAVING_ADJUSTMENT: new TPEAction('SET_IS_SAVING_ADJUSTMENT'),
    SET_SAVE_ADJUSTMENT_ERROR: new TPEAction('SET_SAVE_ADJUSTMENT_ERROR'),
    SET_SAVE_ADJUSTMENT_RESPONSE: new TPEAction('SET_SAVE_ADJUSTMENT_RESPONSE'),
    SET_EXPORT_PERIOD_CHARGES_STATUS: new TPEAction('SET_EXPORT_PERIOD_CHARGES_STATUS'),
    SET_EXPORT_PERIOD_CHARGES_FILE_URL: new TPEAction('SET_EXPORT_PERIOD_CHARGES_FILE_URL'),
    SET_SHOW_UPLOAD_PERIOD_CHARGES: new TPEAction('SET_SHOW_UPLOAD_PERIOD_CHARGES'),
    SET_S3_KEY_FOR_UPLOAD: new TPEAction('SET_S3_KEY_FOR_UPLOAD'),
});

export function periodChargesReducer(state: PeriodChargesState, action: TPEAction) : PeriodChargesState {
    switch(action.type) {
        case ACTIONS.SET_AVAILABLE_ASSIGNEES.type:
            return {
                ...state,
                availableAssignees: action.payload,
            };
        case ACTIONS.SET_AVAILABLE_STATUSES.type:
            return {
                ...state,
                availableStatuses: action.payload,
            };
        case ACTIONS.SET_AVAILABLE_YEARS.type:
            return {
                ...state,
                availableYears: action.payload,
            };
        case ACTIONS.SET_SELECTED_YEAR.type:
            return {
                ...state,
                selectedYear: action.payload,
            };
        case ACTIONS.SET_SELECTED_ASSIGNEE.type:
            return {
                ...state,
                selectedAssignee: action.payload,
            };
        case ACTIONS.SET_SEARCH_CLI.type:
            return {
                ...state,
                searchCLI: action.payload,
            };
        case ACTIONS.SET_SELECTED_STATUS.type:
            return {
                ...state,
                selectedStatus: action.payload,
            };
        case ACTIONS.SET_SEARCH_PAYLOAD.type:
            return {
                ...state,
                searchPayload: action.payload,
            };
        case ACTIONS.SET_CURRENT_PAGE.type:
            return {
                ...state,
                currentPage: action.payload,
            };
        case ACTIONS.SET_PERIOD_CHARGES.type:
            return {
                ...state,
                periodCharges: action.payload,
            };
        case ACTIONS.SET_SELECTED_PERIOD_CHARGES.type:
            return {
                ...state,
                selectedPeriodCharges: action.payload,
            };
        case ACTIONS.SET_FIRST_PERIOD_CHARGE_AS_SELECTED.type:
            const { periodCharges } = state;
            const firstPeriodCharge = periodCharges.length > 0? periodCharges[0] : null;

            return {
                ...state,
                selectedPeriodCharges: firstPeriodCharge == null? [] : [firstPeriodCharge],
            };
        case ACTIONS.SET_CLI_BALANCES.type:
            return {
                ...state,
                cliBalances: action.payload,
            };
        case ACTIONS.SET_IS_SEARCHING_PERIOD_CHARGES.type:
            return {
                ...state,
                searchPeriodChargesIsLoading: action.payload,
            };
        case ACTIONS.SET_PERIOD_CHARGES_ERROR.type:
            return {
                ...state,
                searchPeriodChargesError: action.payload,
            };
        case ACTIONS.SET_IS_SAVING_ADJUSTMENT.type:
            return {
                ...state,
                balanceAdjustmentIsSaving: action.payload,
            };
        case ACTIONS.SET_SAVE_ADJUSTMENT_ERROR.type:
            return {
                ...state,
                balanceAdjustmentError: action.payload,
            };
        case ACTIONS.SET_SAVE_ADJUSTMENT_RESPONSE.type:
            return {
                ...state,
                saveAdjustmentResponse: action.payload,
            };
        case ACTIONS.SET_EXPORT_PERIOD_CHARGES_STATUS.type:
            return {
                ...state,
                exportPeriodChargesStatus: action.payload,
            };
        case ACTIONS.SET_EXPORT_PERIOD_CHARGES_FILE_URL.type:
            return {
                ...state,
                exportPeriodChargesFileURL: action.payload,
            };
        case ACTIONS.SET_SHOW_UPLOAD_PERIOD_CHARGES.type:
            return {
                ...state,
                showUploadPeriodCharges: action.payload,
            };
        case ACTIONS.SET_S3_KEY_FOR_UPLOAD.type:
            return {
                ...state,
                s3KeyForUpload: action.payload,
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}