import apiService from "src/services/ApiCallService";
import {useEffect, useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import GetMECStatusDataResult, {
    GroupExecutionStatus,
    MECStatusDataRow
} from "src/models/mec/status/GetMECStatusDataResult";
import GetMECCLIDetailsResult from "src/models/mec/status/GetMECCLIDetailsResult";
import { Reversal } from "src/models/mec/status/Reversal";
import { Workbook } from "src/models/agreements/Workbook";
import apiCallService from "src/services/ApiCallService";
import CONSTANTS, { FAILED_EXECUTION_STATUSES, IN_PROGRESS_EXECUTION_STATUSES, REVERSAL_ALLOWED_EXECUTION_STATUSES } from "src/utils/constants";
import FormattingService from "../utils/FormattingService";
import DateUtils from "src/utils/dateUtils";

export default class MECService {

    /**
    * Gets data for the Status table of MEC Status
    * @param period The period to get status data for
    */
    getMECStatusData(query: string, period: string): [GetMECStatusDataResult, GroupExecutionStatus[], boolean, string] {
        const [results, setResults] = useState(null as unknown as GetMECStatusDataResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');
        const [statuses, setStatuses] = useState<GroupExecutionStatus[]>([]);

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiCallService.getMECStatusData(period);
                    const json = await response.json();
                    const status = json.groupExecutions as GroupExecutionStatus[];
                    setStatuses(status);
                    setResults(formatMECStatusDataResults(status));
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!StringUtils.isNullOrEmpty(period)) {
                fetchData()
            }
        }, [query, period]);
        return [results, statuses, loading, error];
    }

    /**
    * Gets data for the CLI details table of MEC Status
    * @param group The group to get CLI details for
    */
    getGroupExecutionCLIDetails(query: string, group: string, period: string) : [GetMECCLIDetailsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as GetMECCLIDetailsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const groupNumber = group.substring(group.indexOf(' ') + 1);
                    const response = await apiService.getGroupExecutionCLIDetails(groupNumber, period);
                    const json = await response.json() as GetMECCLIDetailsResult;
                    setResults(json);
                }
                catch (ex) {
                    setResults(null as unknown as GetMECCLIDetailsResult);
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(group) && !StringUtils.isNullOrEmpty(period)) {
                fetchData();
            }
        }, [query, period]);
        return [results, loading, error];
    }

    getBulkReversals(query: string, period: string) : [Reversal[], boolean, string] {
        const [results, setResults] = useState<Reversal[]>([]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData(period: string) {
                try {
                    setLoading(true);
                    const response = await apiCallService.getBulkReversals(period);
                    const json = await response.json();
                    const reversals = json.reversals as Reversal[];
                    reversals.forEach(r => formatReversal(r));
                    setResults(reversals);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(period)) {
                fetchData(period)
            }
        }, [query, period]);
        return [results, loading, error];
    }

    getWorkbooks(query: string) : [Workbook[], boolean, string] {
        const [results, setResults] = useState<Workbook[]>([]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getWorkbooks();
                    const json = await response.json();
                    setResults(json.workbooks);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData();
            }
        }, [query]);
        return [results, loading, error];
    }

    searchItemForReversal(query: string, period: string, workbookId?: string, calculationNumber?: string): [Reversal, boolean, string] {
        const [results, setResults] = useState(null as unknown as Reversal);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData(period: string, workbookId?: string, calculationNumber?: string) {
                try {
                    setLoading(true);
                    const response = await apiService.searchItemForReversal(period, workbookId, calculationNumber);
                    const json = await response.json();
                    const reversal: Reversal = json.reversal;
                    formatReversal(reversal);
                    setResults(reversal);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(period)) {
                fetchData(period, workbookId, calculationNumber);
            }
        }, [query]);
        return [results, loading, error];
    }

    getLastGLDataIngestionTimestamp(query: string): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiCallService.getLastGLDataIngestionTimestamp();
                    const json = await response.json();
                    setResult(json.lastGLBalanceIngestionTimestamp == null ? 'Unavailable' : 
                        DateUtils.formatTimestamp(json.lastGLBalanceIngestionTimestamp));
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData();
            }
        }, [query]);
        return [result, loading, error];
    }

    /**
    * Closes the current period and opens the next one
    * @param period The period to close
    */
    handOff(period: string | null): [string, boolean, string] {
        const [newPeriod, setNewPeriod] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiCallService.mecHandOff();
                    const json = await response.json();
                    setNewPeriod(json.period);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(period)) {
                fetchData()
            }
        }, [period]);
        return [newPeriod, loading, error];
    }

    /**
    * Closes the current period and opens the next one
    * @param period The period to close
    */
    getHandOffStatus(query: string): [string, boolean, boolean, string] {
        const [currentPeriod, setCurrentPeriod] = useState('');
        const [handOffAvailable, setHandOffAvailable] = useState(false);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiCallService.getHandOffStatus();
                    const json = await response.json();
                    setCurrentPeriod(json.period);
                    setHandOffAvailable(json.handOffAvailable);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData()
            }
        }, [query]);
        return [currentPeriod, handOffAvailable, loading, error];
    }
}

export function formatMECStatusDataResults(data: GroupExecutionStatus[]): GetMECStatusDataResult {
    let currentDay = 0;
    let headerRowCounter = 0;

    const results: GetMECStatusDataResult = {
        records: [],
    };

    data.forEach(record => {
        const day = record.executionDay;
        const progress = 100 * record.numberOfCompletedCLIS / record.numberOfCLIs;
        const formattingService: FormattingService = new FormattingService();
        const groupRow = record as unknown as MECStatusDataRow;

        if (day > currentDay) {
            headerRowCounter = results.records.length;

            const dayRow: MECStatusDataRow = {
                dayOrGroup: "Day " + day,
                status: record.status,
                progress: "0%",
                numberOfCLIs: 0,
                numberOfCompletedCLIS: 0,
                estimatedStart: record.estimatedStart,
                systemDependency: record.systemDependency,
                manualCalcDependency: record.manualCalcDependency,
                runBy: record.runBy,
                otherDependencies: record.otherDependencies,
                headerRow: headerRowCounter,
                dryRun: record.dryRun
            };
            currentDay = day;
            results.records.push(dayRow)
        }

        groupRow.dayOrGroup = record.group
        groupRow.headerRow = headerRowCounter;
        groupRow.progress = isNaN(progress) ? "0%" : formattingService.formatNumber(progress) + "%";
        if (groupRow.startTime != null) {
            groupRow.startTime = DateUtils.formatTimestamp(groupRow.startTime);
        }
        if (groupRow.endTime != null) {
            groupRow.endTime = DateUtils.formatTimestamp(groupRow.endTime);
        }

        results.records.push(groupRow);

        if(groupRow.numberOfCLIs && groupRow.numberOfCompletedCLIS){
            results.records[headerRowCounter].numberOfCLIs+= groupRow.numberOfCLIs;
            results.records[headerRowCounter].numberOfCompletedCLIS+= groupRow.numberOfCompletedCLIS;

            const groupsInDayProgress = 100 * results.records[headerRowCounter].numberOfCompletedCLIS / results.records[headerRowCounter].numberOfCLIs;
            results.records[headerRowCounter].progress = isNaN(groupsInDayProgress) ? "0%": formattingService.formatNumber(groupsInDayProgress) +"%";
        }

        /* If the status of the Day header row is not in error, update the status of the header row to the status of its most recent group execution
        (only if the groups status is in progress or in error).
        */
        const dayHeaderRowStatus = results.records[headerRowCounter].status;
        if (!FAILED_EXECUTION_STATUSES.includes(dayHeaderRowStatus)) {
            if ((FAILED_EXECUTION_STATUSES.includes(groupRow.status) || IN_PROGRESS_EXECUTION_STATUSES.includes(groupRow.status))) {
                results.records[headerRowCounter].status = groupRow.status;
            }
        }
    })
    return results;
}

function formatReversal(reversal: Reversal) {
    switch (reversal.type?.toLowerCase()) {
        case CONSTANTS.REVERSAL_ITEM_TYPES.WORKBOOK.toLowerCase():
            reversal.name = reversal.workbookName;
            reversal.type = CONSTANTS.REVERSAL_ITEM_TYPES.WORKBOOK;
            break;
        case CONSTANTS.REVERSAL_ITEM_TYPES.CLI.toLowerCase():
            reversal.name = reversal.calculationNumber;
            reversal.type = CONSTANTS.REVERSAL_ITEM_TYPES.CLI;
            break;
        case CONSTANTS.REVERSAL_ITEM_TYPES.GROUP.toLowerCase():
            reversal.name = reversal.group;
            reversal.type = CONSTANTS.REVERSAL_ITEM_TYPES.GROUP;
            break;
    }
    
    if (reversal.reversedOn != null) {
        reversal.reversedOn = DateUtils.formatTimestamp(reversal.reversedOn);
    }
    if (reversal.reversalEndDate != null) {
        reversal.reversalEndDate = DateUtils.formatTimestamp(reversal.reversalEndDate);
    }
    const hasAllowedReversalStatus = REVERSAL_ALLOWED_EXECUTION_STATUSES.includes(reversal.status);
    reversal.isDisabled = reversal.dryRun === true || !hasAllowedReversalStatus? true : false;
    if (reversal.isDisabled){
        reversal.tooltip = `Reversal disabled because ${reversal.dryRun? "it is a dry run ":""}${reversal.dryRun && !hasAllowedReversalStatus? `and status is ${reversal.status}` : (hasAllowedReversalStatus? "" : `status is ${reversal.status}`)}`
    }
}
