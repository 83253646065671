import WebSocketApi from './WebSocketApi';
import CONSTANTS from '../utils/constants';

/**
 * Allows interaction with the Balance Pull Websocket API
 */
export default class ATPWebSocketApiClient extends WebSocketApi {

    

    /**
     * Checks if connected and reconnects if connection is closed
     */
    ensureConnected() {
        super.ensureConnected(CONSTANTS.ENVIRONMENT_VARIABLES.WEBSOCKETS_API_URL);
    }

    /**
     * Starts a new balance pull request
     * @param payload The balance pull parameters
     */
    requestBalancePull(payload: any){
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.PULL_GL_BALANCE, payload);
    }

    /**
     * Starts a request to execute a CLI
     * @param payload The execute CLI request
     */
     executeCLI(payload: any) {
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_CLI, payload);
    }

    /**
     * Starts a request to pull a CDT value
     * @param payload The pull CDT Value request
     */
     pullCDTValue(payload: any) {
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.PULL_CDT_VALUE, payload);
    }

    /**
     * Starts a request to dry run a calculation
     * @param payload The dry-run request
     */
    dryRunCalculation(payload: any) {
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.DRY_RUN, payload);
    }

    /**
     * Starts a request to generate review journals
     * @param payload The generate Review Journals request
     */
    generateReviewJournals(payload: any) {
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.GENERATE_REVIEW_JOURNALS, payload);
    }

    /**
     * Starts a request to execute a worksheet
     * @param payload The execute worksheet request
     */
    executeTPAllocationWorksheet(payload: any) {
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_WORKSHEET, payload);
    }

    /**
     * Starts a request to execute a group
     * @param payload The execute group request
     */
    executeGroup(payload: any){
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_GROUP, payload);
    }

    /**
     * Starts a request to execute a bulk reversal
     * @param payload The execute bulk reversal request
     */
    executeBulkReversal(payload: any){
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_BULK_REVERSAL, payload);
    }

    performBulkAction(payload: any){
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.PERFORM_BULK_ACTION, payload);
    }


    exportPeriodCharges(payload: any){
        this.ensureConnected();
        this.sendMessage(CONSTANTS.WEB_SOCKET_API_ROUTES.EXPORT_PERIOD_CHARGES, payload);
    }
}